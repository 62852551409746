<template>
    <div class="box box_mm">
        <div class="box_main">
            <div class="cont_main_box">
            	<el-form ref="form" :model="form" label-width="130px">
            	  <el-form-item label="会议消息通知">
            	    <el-switch v-model="form.delivery"></el-switch>
            	  </el-form-item>
				  <el-form-item label="提案通知">
				    <el-switch v-model="form.delivery2"></el-switch>
				  </el-form-item>
				  <el-form-item label="活动消息通知">
				    <el-switch v-model="form.delivery3"></el-switch>
				  </el-form-item>
				  <el-form-item label="履职报告消息通知">
				    <el-switch v-model="form.delivery4"></el-switch>
				  </el-form-item>
				  <el-form-item label="短信通知">
				    <el-switch v-model="form.delivery5"></el-switch>
				  </el-form-item>
				  <el-form-item label="代办通知">
				    <el-switch v-model="form.delivery6"></el-switch>
				  </el-form-item>
				  <el-form-item label="消息盒子通知">
				    <el-switch v-model="form.delivery7"></el-switch>
				  </el-form-item>
            	</el-form>
				
				<div style="margin-top: 180px;display: flex;align-items: center;">
					<el-button>取消</el-button>
					<el-button type="primary" @click="onSubmit">保存</el-button>
				</div>
            </div>
        </div>

    </div> 
</template>

<script>
export default {
    data() {
        return {
			form: {
			  delivery: true,
			  delivery2: true,
			  delivery3: true,
			  delivery4: true,
			  delivery5: true,
			  delivery6: true,
			  delivery7: true,
			}
        }
    },
    mounted() {
        
    },
    methods: {
		onSubmit(){
			this.$message.success('保存成功')
		}
    }
}
</script>

<style lang="scss" scoped>
.box_main {
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    min-height: 100%;
	overflow: hidden;
	.cont_main_box{
		padding: 0.5rem;
	}
}
</style>